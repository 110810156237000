import axios from "axios";

const BACKEND_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

export const sendEvent = async (token, eventName, properties) => {
  const { data } = await axios({
    method: "post",
    url: `${BACKEND_BASE_URL}/api/events/capture`,
    data: {
      name: eventName,
      properties: properties,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
