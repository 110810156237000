import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppUser } from "../hooks/useAppUser";
import { useOnboarding } from "../hooks/useOnboarding";

export default function OnboardingPage() {
  const navigate = useNavigate();
  const { isOnboardingCompleted } = useAppUser();

  useEffect(() => {
    if (isOnboardingCompleted) {
      navigate("/home");
    }
  }, [isOnboardingCompleted]);

  const { finishOnboarding, isLoading } = useOnboarding();

  return (
    <>
      <h1 className="text-3xl font-bold underline">Onboarding page</h1>
      <p>Here the onboarding steps</p>
      <button
        onClick={() => finishOnboarding()}
        className="bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block w-full rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Complete Onboarding"}
      </button>
    </>
  );
}
