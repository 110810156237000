import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "react-query";
import { sendEvent } from "../api/events";

export const useCaptureEvent = () => {
  const { getToken } = useAuth();

  const mutation = useMutation(async (vars) => {
    const token = await getToken();
    return sendEvent(token, vars.eventName, vars.properties); // Fire-and-Forget (i.e, do not await)
  });

  const captureEvent = (eventName, properties) =>
    mutation.mutate({ eventName, properties });

  return {
    captureEvent,
  };
};
