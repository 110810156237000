import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppUser } from "../hooks/useAppUser";
import Pricing from "../sections/Pricing";

export default function UpgradePlanPage() {
  const navigate = useNavigate();
  const { isOnPaidTier } = useAppUser();

  useEffect(() => {
    if (isOnPaidTier) {
      navigate("/home");
    }
  }, [isOnPaidTier]);

  return (
    <>
      <NavLink to="/home">Back</NavLink>
      <Pricing />
    </>
  );
}
