import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "react-query";
import { createCustomerPortalSession } from "../api/payments";

export const useStripeCustomerPortal = () => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async () => {
      const token = await getToken();
      const successUrl = "/home";
      return await createCustomerPortalSession(token, successUrl);
    },
    {
      onSuccess: (data) => {
        window.location.href = data.url; // Browser redirect
      },
    }
  );

  const goToStripeCustomerPortal = () => mutation.mutate();
  const isLoading = mutation.isLoading || mutation.isSuccess;

  return { goToStripeCustomerPortal, isLoading };
};
