import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useStripeCheckout } from "../hooks/useStripeCheckout";
import { useAppUser } from "../hooks/useAppUser";
import LoadingScreen from "../components/LoadingScreen";

const priceIds = {
  Basic: "price_1PEYKRCFS2FIlw6sUbiEhuEK",
  Pro: "price_1PEYKhCFS2FIlw6sQ1cmK7AN",
  Free: "free",
};

export default function DirectPaymentPage() {
  const navigate = useNavigate();
  const { isLoaded, isOnPaidTier } = useAppUser();

  const [searchParams, _] = useSearchParams();
  const plan = searchParams.get("plan");
  // const billingPeriod = searchParams.get("billingPeriod");

  const priceId = priceIds[plan];

  const successUrl = "/home?success=true";
  const cancelUrl = "/home";
  const { goToStripeCheckout } = useStripeCheckout(
    priceId,
    successUrl,
    cancelUrl
  );

  useEffect(() => {
    if (isLoaded) {
      if (isOnPaidTier || !priceId) {
        navigate("/home");
      }
      goToStripeCheckout();
    }
  }, [isLoaded, isOnPaidTier]);

  return <LoadingScreen />;
}
