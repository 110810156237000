import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "react-query";
import { completeOnboarding } from "../api/users";

export const useOnboarding = () => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async () => {
      const token = await getToken();
      return await completeOnboarding(token);
    },
    {
      onSuccess: () => {
        location.reload();
      },
    }
  );

  const finishOnboarding = () => mutation.mutate();
  const isLoading = mutation.isLoading || mutation.isSuccess;

  return { finishOnboarding, isLoading };
};
