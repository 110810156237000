import { useStripeCustomerPortal } from "../hooks/useStripeCustomerPortal";

export default function SubscriptionSettingsPage() {
  const { goToStripeCustomerPortal, isLoading } = useStripeCustomerPortal();

  return (
    <button
      onClick={() => goToStripeCustomerPortal()}
      className="bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block w-full rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10"
      disabled={isLoading}
    >
      {isLoading ? "Loading..." : "Go to Customer Portal"}
    </button>
  );
}
