import { useAuth } from "@clerk/clerk-react";
import { useMutation } from "react-query";
import { createCheckoutSession } from "../api/payments";

export const useStripeCheckout = (priceId, successUrl, cancelUrl) => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async () => {
      const token = await getToken();
      return await createCheckoutSession(token, priceId, successUrl, cancelUrl);
    },
    {
      onSuccess: (data) => {
        window.location.href = data.url; // Browser redirect
      },
    }
  );

  const goToStripeCheckout = () => mutation.mutate();
  const isLoading = mutation.isLoading || mutation.isSuccess;

  return { goToStripeCheckout, isLoading };
};
