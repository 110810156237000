import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppUser } from "../hooks/useAppUser";
import LoadingScreen from "../components/LoadingScreen";

export default function PlanLayout() {
  const navigate = useNavigate();
  const { isLoaded, isSignedIn } = useAppUser();

  useEffect(() => {
    if (isLoaded) {
      if (!isSignedIn) {
        navigate("/sign-in");
      }
    }
  }, [isLoaded]);

  return isLoaded ? <Outlet /> : <LoadingScreen />;
}
