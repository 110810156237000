import { SignIn } from "@clerk/clerk-react";

export default function SignInPage() {
  return (
    <div className="flex items-center justify-center mt-24">
      <SignIn
        path="/sign-in"
        signUpUrl="/sign-up"
        forceRedirectUrl="/home"
        fallbackRedirectUrl="/home"
        signUpForceRedirectUrl="/home"
        appearance={{ variables: { colorPrimary: "#4F46E5" } }}
      />
    </div>
  );
}
