import axios from "axios";

const BACKEND_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

export const completeOnboarding = async (token) => {
  const { data } = await axios({
    method: "post",
    url: `${BACKEND_BASE_URL}/api/users/complete-onboarding`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
