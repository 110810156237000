import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppUser } from "../hooks/useAppUser";
import NavBar from "../sections/NavBar";
import LoadingScreen from "../components/LoadingScreen";

export default function AppLayout() {
  const navigate = useNavigate();
  const { isLoaded, isSignedIn, isOnboardingCompleted } = useAppUser();

  useEffect(() => {
    if (isLoaded) {
      if (!isSignedIn) {
        navigate("/sign-in");
      }

      if (!isOnboardingCompleted) {
        navigate("/plan/onboarding");
      }
    }
  }, [isLoaded]);

  return isLoaded ? (
    <>
      <NavBar />
      <Outlet />
    </>
  ) : (
    <LoadingScreen />
  );
}
