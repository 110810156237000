// Sentry initialization should be imported first!
import "./utils/sentry";

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Import the layouts
import RootLayout from "./layouts/root-layout";
import PlanLayout from "./layouts/plan-layout";
import AppLayout from "./layouts/app-layout";

// Import the Page components
import RootPage from "./pages/root";
import SignInPage from "./pages/sign-in";
import SignUpPage from "./pages/sign-up";
import HomePage from "./pages/home";
import UpgradePlanPage from "./pages/upgrade";
import NotFoundPage from "./pages/notfound";
import OnboardingPage from "./pages/onboarding";
import DirectPaymentPage from "./pages/direct-payment";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "/", element: <RootPage /> },
      { path: "/sign-in", element: <SignInPage /> },
      { path: "/sign-up", element: <SignUpPage /> },
      {
        element: <PlanLayout />,
        path: "plan",
        children: [
          { path: "/plan/onboarding", element: <OnboardingPage /> },
          { path: "/plan/direct-payment", element: <DirectPaymentPage /> },
          { path: "/plan/upgrade", element: <UpgradePlanPage /> },
        ],
      },
      {
        element: <AppLayout />,
        path: "home",
        children: [
          { path: "/home", element: <HomePage /> },
        ],
      },
      { path: "*", element: <NotFoundPage backToUrl="/home" /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
