import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";

export default function RootPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/home");
  }, []);

  return <LoadingScreen />;
}
