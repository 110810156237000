import { useUser } from "@clerk/clerk-react";

export const useAppUser = () => {
  const { user, isLoaded, isSignedIn } = useUser();

  const isOnPaidTier =
    isLoaded &&
    isSignedIn &&
    user.publicMetadata?.subscriptionTier !== "Free" &&
    user.publicMetadata?.subscriptionTier !== undefined;

  const isOnboardingCompleted =
    (isLoaded && isSignedIn && user.publicMetadata?.isOnboardingCompleted) ||
    false;

  const isStripeCustomer =
    (isLoaded && isSignedIn && user.publicMetadata?.isStripeCustomer) || false;

  return {
    isLoaded,
    isSignedIn,
    isOnPaidTier,
    isOnboardingCompleted,
    isStripeCustomer,
  };
};
