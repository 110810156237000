import { useCaptureEvent } from "../hooks/useCaptureEvent";

export default function HomePage() {
  const { captureEvent } = useCaptureEvent();
  return (
    <>
      <h1 className="text-3xl font-bold underline">Home page</h1>
      <p>This is a protected page.</p>
      <button
        onClick={() => captureEvent("test", { foo: "bar" })}
        className="p-5 m-5 border rounded-md shadow-md"
      >
        Send user event
      </button>
    </>
  );
}
