import axios from "axios";

const BACKEND_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;
const FRONTEND_APP_BASE_URL = import.meta.env.VITE_FRONTEND_APP_BASE_URL;

export const createCustomerPortalSession = async (token, successUrl) => {
  const { data } = await axios({
    method: "post",
    url: `${BACKEND_BASE_URL}/api/payments/create-customer-portal-session`,
    data: {
      successUrl: `${FRONTEND_APP_BASE_URL}${successUrl}`,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const createCheckoutSession = async (
  token,
  priceId,
  successUrl,
  cancelUrl
) => {
  const { data } = await axios({
    method: "post",
    url: `${BACKEND_BASE_URL}/api/payments/create-checkout-session`,
    data: {
      priceId: priceId,
      successUrl: `${FRONTEND_APP_BASE_URL}${successUrl}`,
      cancelUrl: `${FRONTEND_APP_BASE_URL}${cancelUrl}`,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};
