import { SignUp } from "@clerk/clerk-react";
import { useSearchParams } from "react-router-dom";

export default function SignUpPage() {
  const [searchParams, _] = useSearchParams();
  const plan = searchParams.get("plan");
  const billingPeriod = searchParams.get("billingPeriod");
  const buyerIntent = plan && billingPeriod;

  const redirectUrl = buyerIntent
    ? `/plan/direct-payment?plan=${plan}&billingPeriod=${billingPeriod}`
    : "/home";

  return (
    <div className="flex items-center justify-center mt-24">
      <SignUp
        path="/sign-up"
        signInUrl="/sign-in"
        forceRedirectUrl={redirectUrl}
        fallbackRedirectUrl={redirectUrl}
        signInForceRedirectUrl={redirectUrl}
        appearance={{ variables: { colorPrimary: "#4F46E5" } }}
      />
    </div>
  );
}
