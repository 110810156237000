import { UserButton } from "@clerk/clerk-react";
import SubscriptionSettingsPage from "../pages/subscription-settings";
import { CreditCardIcon } from "@heroicons/react/24/outline";
import { useAppUser } from "../hooks/useAppUser";

export default function UserProfileAvatar() {
  const { isStripeCustomer } = useAppUser();

  return (
    <UserButton afterSignOutUrl="/sign-in">
      {isStripeCustomer && (
        <UserButton.UserProfilePage
          label="Subscription"
          url="subscription"
          labelIcon={<CreditCardIcon />}
        >
          <SubscriptionSettingsPage />
        </UserButton.UserProfilePage>
      )}
      <UserButton.UserProfilePage label="account" />
      <UserButton.UserProfilePage label="security" />
    </UserButton>
  );
}
